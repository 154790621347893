<template>
  <v-data-table
    mobile-breakpoint="0"
    :headers="aHeaders"
    :disable-sort="true"
    :items="aTable"
    :loading="bLoadingTable"
    loading-text="Cargando datos..."
    :items-per-page="-1"
    :hide-default-footer="true"
    no-data-text="No hay información disponible para mostrar."
    :footer-props="{
      'items-per-page-text': 'Filas por página',
      'items-per-page-all-text': 'Todos',
    }"
    class="global-table"
  >
    <template slot="progress">
      <v-progress-linear
        color="var(--primary-color-loading-table)"
        indeterminate
      ></v-progress-linear>
    </template>
    <template v-slot:[`item.dBalance`]="{ item }">
      <span class="txt-pop-SemiBold">{{ item.dBalance }}</span>
    </template>
    <template v-slot:[`item.dVariation`]="{ item }">
      <span class="txt-pop-SemiBold"> {{ item.dVariation }} </span>
    </template>

    <template v-slot:footer>
      <footer-table-component-global
        :numPages="numPages"
        :totalItems="totalItems"
        @changeTable="getEmitFinancialAccounts"
      />
    </template>
  </v-data-table>
</template>
<script>
export default {
  props: {
    aTable: Array,
    aHeaders: Array,
    bLoadingTable: Boolean,
    numPages: Number,
    totalItems: Number,
  },
  data() {
    return {
      iItemsPerPage: 5,
    };
  },
  methods: {
    getEmitFinancialAccounts(obj) {
      this.$emit("getEmitFinancialAccounts", obj);
    },
  },
};
</script>

<style>
.header-table-payable-balance-account-receivable {
  padding-right: 40px !important;
}
.body-table-payable-balance-account-receivable {
  padding-right: 40px !important;
}
</style>
