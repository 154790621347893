var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"global-table",attrs:{"mobile-breakpoint":"0","headers":_vm.aHeaders,"disable-sort":true,"items":_vm.aTable,"loading":_vm.bLoadingTable,"loading-text":"Cargando datos...","items-per-page":-1,"hide-default-footer":true,"no-data-text":"No hay información disponible para mostrar.","footer-props":{
    'items-per-page-text': 'Filas por página',
    'items-per-page-all-text': 'Todos',
  }},scopedSlots:_vm._u([{key:"item.dBalance",fn:function(ref){
  var item = ref.item;
return [_c('span',{staticClass:"txt-pop-SemiBold"},[_vm._v(_vm._s(item.dBalance))])]}},{key:"item.dVariation",fn:function(ref){
  var item = ref.item;
return [_c('span',{staticClass:"txt-pop-SemiBold"},[_vm._v(" "+_vm._s(item.dVariation)+" ")])]}},{key:"footer",fn:function(){return [_c('footer-table-component-global',{attrs:{"numPages":_vm.numPages,"totalItems":_vm.totalItems},on:{"changeTable":_vm.getEmitFinancialAccounts}})]},proxy:true}],null,true)},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"var(--primary-color-loading-table)","indeterminate":""}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }